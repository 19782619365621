
.bread-crumbs {
    justify-self: center;
    box-sizing: border-box;
    padding: 10px 0px;
}

.bread-crumbs a {
    line-height: 22px;
    color: #4E5962;
    text-decoration: none
}

@media ( min-width: 1200px ) {
    .bread-crumbs { width: 1200px; }
}
@media ( min-width: 768px )  and ( max-width: 1199px ) {
    .bread-crumbs { width: 688px; }
}
@media ( max-width: 767px ) {
    .bread-crumbs { width: 100%; padding: 10px 15px }
}
